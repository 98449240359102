body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: '#2d3748';
  width: 100%
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

pre {
  white-space: pre-wrap;       /* css-3 */
  white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
  white-space: -pre-wrap;      /* Opera 4-6 */
  white-space: -o-pre-wrap;    /* Opera 7 */
  word-wrap: break-word; 
}

.markdown pre:first-child, .markdown p:first-child, .markdown ul:first-child, .markdown ol:first-child, .markdown blockquote:first-child {
  margin-top: 0;
}
.markdown pre:last-child, .markdown p:last-child, .markdown ul:last-child, .markdown ol:last-child, .markdown blockquote:last-child {
  margin-bottom: 0;
}
.markdown ul {
  margin-top: 1em;
  margin-bottom: 1em;
  padding-left: 2em;
  list-style-type: circle;
}
.markdown ol {
  margin-top: 1em;
  margin-bottom: 1em;
  padding-left: 2em;
  list-style-type: decimal;
}
.markdown li {
  padding-left: .25em;
}
.markdown p {
  margin-top: 1em;
  margin-bottom: 1em;
}


  


@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {

    .scrollbar {
      scrollbar-gutter: stable;
    }

    .scrollbar::-webkit-scrollbar {
      width: 10px;
      height: 5px;
    }
  
    .scrollbar::-webkit-scrollbar-track {
      border-radius: 100vh;
    }
  
    .scrollbar::-webkit-scrollbar-thumb {
      background: #ffffff50;
      border-radius: 100vh;
      border: 2px solid #ffffff50;
    }
  
    .scrollbar::-webkit-scrollbar-thumb:hover {
      background: #010200;
    }

    .draggable {
      -webkit-user-select: none;
      -webkit-app-region: drag;
    }
    .not-draggable {
      -webkit-app-region: no-drag;
    }

    .scrollbar-hide {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
    }

    .scrollbar-hide::-webkit-scrollbar {
      display: none;
    }
  }



